import React from "react"
import PropTypes from "prop-types"

const Fiverr = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
         viewBox="0 0 16 16"
         fill={color}
    >
    <path d="M13 13V5H5v-.5C5 3.673 5.673 3 6.5 3H8V0H6.5A4.505 4.505 0 0 0 2 4.5V5H0v3h2v5H0v3h7v-3H5V8h5.028v5H8v3h7v-3h-2z"/>
    <circle cx="11.5" cy="1.5" r="1.5"/>
    </svg>
)}

Fiverr.propTypes = {
  color: PropTypes.string,
}

Fiverr.defaultProps = {
  color: "#000000",
}

export default Fiverr
