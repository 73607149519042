import React from "react"
import PropTypes from "prop-types"

const Gumroad = ({ color }) => {
  return (

<svg xmlns="http://www.w3.org/2000/svg" 
 enableBackground="new 0 0 28 28"
 viewBox="0 0 28 28"
 fill={color}>
<path fill="#FFA0C6" d="M22.45,23.815H4.55c-1.56,0-2.825-1.265-2.825-2.825V5.866c0-1.56,1.265-2.825,2.825-2.825h17.9c1.56,0,2.825,1.265,2.825,2.825v15.125C25.275,22.551,24.01,23.815,22.45,23.815z"/>
<path d="M22.5,26h-18C3.121,26,2,24.878,2,23.5V6c0-1.378,1.121-2.5,2.5-2.5h18C22.776,3.5,23,3.724,23,4s-0.224,0.5-0.5,0.5h-18C3.673,4.5,3,5.173,3,6v17.5C3,24.327,3.673,25,4.5,25h18c0.827,0,1.5-0.673,1.5-1.5V13c0-0.827-0.673-1.5-1.5-1.5H12c-0.827,0-1.5,0.673-1.5,1.5v3c0,0.827,0.673,1.5,1.5,1.5h4.339c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H12c-1.379,0-2.5-1.122-2.5-2.5v-3c0-1.378,1.121-2.5,2.5-2.5h10.5c1.379,0,2.5,1.122,2.5,2.5v10.5C25,24.878,23.879,26,22.5,26z"/>
<path d="M24 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2S25.103 6 24 6zM24 3c-.552 0-1 .449-1 1s.448 1 1 1 1-.449 1-1S24.552 3 24 3zM18 20c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2S19.103 20 18 20zM18 17c-.552 0-1 .449-1 1s.448 1 1 1 1-.449 1-1S18.552 17 18 17z"/>
</svg>
)}
Gumroad.propTypes = {
  color: PropTypes.string,
}

Gumroad.defaultProps = {
  color: "#000000",
}

export default Gumroad